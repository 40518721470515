import React from 'react'
import './Products.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';

const Products = () => {
  const { t } = useTranslation();

  document.title = t("page-title.products");

  return (
    <div className="UIProducts">
      <div className="container-fluid background">
        <div className="bg-image"></div>
        <div className="container">
          <div className="heading">
            <h2>{t('products.whatwedo')}</h2>
            <h1>{t('products.ngenoffers')}</h1>
          </div>
        </div>
      </div>
      <div className="container cards-container">

        <div className="card" id="households">
          <img src={`${process.env.PUBLIC_URL}/images/cards/households.png`} alt={t('products.households')}/>
          <div className="card-content">
            <h4>{t('products.households')}</h4>
            <p>{t('products.householdstext')}</p>
            <NavLink to={t('url.gospodinjstva')} exact className="btn-secondary">{t('products.readmore')}</NavLink>
          </div>
        </div>
        <div className="card" id="companies">
          <img src={`${process.env.PUBLIC_URL}/images/cards/companies.png`} alt={t('products.companies')}/>
          <div className="card-content">
            <h4>{t('products.companies')}</h4>
            <p>{t('products.companiestext')}</p>
            <NavLink to={t('url.podjetja')} exact className="btn-secondary">{t('products.readmore')}</NavLink>
          </div>
        </div>
        <div className="card" id="tso">
          <img src={`${process.env.PUBLIC_URL}/images/cards/tso.png`} alt={t('products.tso')}/>
          <div className="card-content">
            <h4>{t('products.tso')}</h4>
            <p>{t('products.tsotext')}</p>
            <NavLink to={t('url.tso')} exact className="btn-secondary">{t('products.readmore')}</NavLink>
          </div>
        </div>

        <div className="card" id="partners">
          <img src={`${process.env.PUBLIC_URL}/images/cards/partners.png`} alt={t('products.partners')}/>
          <div className="card-content">
            <h4>{t('products.partners')}</h4>
            <p>{t('products.partnerstext')}</p>
            <NavLink to={t('url.poslovnipartnerji')} exact className="btn-secondary">{t('products.readmore')}</NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products