import React from 'react'
import './LegalNotice.css';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Impressum = () => {
    const { t } = useTranslation();
    return (
        <div className="UILegalNotice">

            <div className="container-fluid background">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-7">
                            <div className="heading">
                                <h1>{t('pages.impressum')}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container content">
                <div className="text">
                    <p><strong>{t('impressum.text1')}</strong><br/>
                    {t('impressum.text2')}<br/>
                    {t('impressum.text3')}<br/>
                    {t('impressum.text4')}</p>
                    <p>{t('impressum.text5')}: +43463207040<br/>
                    {t('impressum.text6')}: info@ngen-group.eu<br/>
                    {t('impressum.text7')}</p>
                    <p>{t('impressum.text8')}: Matija Dolinar<br/>
                    {t('impressum.text9')}: #<br/>
                    {t('impressum.text10')}: #<br/>
                    {t('impressum.text11')}<br/>
                    {t('impressum.text12')}<br/>
                    {t('impressum.text13')}<br/><br></br>
                    {t('impressum.text14')}<br/>
                    <ul>
                        <li>{t('impressum.text15')}</li>
                        <li>{t('impressum.text16')}</li>
                    </ul>
                    {t('impressum.text17')}</p>
                    <p>
                    {t('impressum.text19')}<br></br>
                    {t('impressum.text18')}
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Impressum