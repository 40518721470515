import React from "react";
import "./Partners.css";
import { useTranslation } from "react-i18next";
import Cardtso from "./Elements/Cardtso";
import Cardhouseholds from "./Elements/Cardhouseholds";
import Cardcompanies from "./Elements/Cardcompanies";
import VideoLower from "./Elements/VideoLower";
import i18n from "i18next";

const Partners = () => {
  var lang = i18n.language;
  const { t } = useTranslation();

  document.title = t("page-title.partners");

  return (
    <div className="UIPartners">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t("tso.ngenponudba")}</h2>
            <h1>{t("pages.poslovnipartnerji")}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img
          src={`${process.env.PUBLIC_URL}/images/products/partners.png`}
          alt=""
          className="starter-whatwedo"
        />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tr.svg`} alt="" />
      </div>
      <div className="container content">
        <div className="text">
          <p>
            {t("partners.text1")}
            <br />
            <br />
            {t("partners.text2")}
          </p>
        </div>
      </div>
      <div className="container-fluid main">
        <div className="container cards">
          <div className="heading">
            <h4>{t("tso.lookat")}</h4>
            <h3>{t("tso.other-products")}</h3>
          </div>
          <div className="row cards">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardtso />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardcompanies />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardhouseholds />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
