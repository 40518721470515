import React, { useState, useEffect } from "react";
import "./JoinUs.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { NavLink } from "react-router-dom";

function JobsList() {
  const [jobs, setJobs] = useState([]);
  const { t } = useTranslation();
  var lang = i18n.language;

  document.title = t("page-title.joinus");

  var file;
  if (lang.includes("_si")) {
   file = "Jobs.json";
  } else if (lang.includes("_pt")) {
    file = "JobsPortugal.json";
  }    

  useEffect(() => {
    const fetchJobs = async () => {
      try {
          const response = await fetch(`${process.env.PUBLIC_URL}/api/${file}`);
        const data = await response.json();
        setJobs(data.jobs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchJobs();
  }, []);

  const filteredJobs = jobs.filter((job) => {
    if (lang.includes("_si")) {
      return job.country === "Slovenija";
    } else if (lang.includes("_pt")) {
      return job.country === "Portugal";
    }    
    return true;
  });

  return (    
    <div class="row cards">
      {filteredJobs.map((job, index) => (
        <div class="col-lg-6" id={index}>
          <NavLink
            to={`${t("url.job-description")}/${index}`}
            className="card-link"
          >
            <div className="card">
              <img
                src={`${process.env.PUBLIC_URL}/images/cards/job-card-trans.png`}
                alt="Job-openings"
              />
              <div className="card-content">
                <h2 className="mb-3">{job.country}</h2>
                <h4>{job.title}</h4>
              </div>
              <div className="readmore">
                <NavLink to={`${t("url.job-description")}/${index}`}>
                  {t("products.readmore")}
                </NavLink>
              </div>
            </div>
          </NavLink>
          <div className="d-flex justify-content-center">
            <div className="card-shadow">
              <img
                src={`${process.env.PUBLIC_URL}/images/cards/shadow.png`}
                alt="shadow card"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

const JoinUs = () => {
  const { t } = useTranslation();
  var lang = i18n.language;

  return (
    <div className="UIJoinUs">
      <div className="container-fluid background">
        <div className="bg-image"></div>
        <div className="container">
          <div className="heading">
            <h2>{t("joinus.joinus")}</h2>
            {!lang.includes("_si") && !lang.includes("_pt") && (
              <h1>{t("pages.zaposlitev")}</h1>
            )}
            {lang.includes("_si") && <h1>{t("joinus.available-jobs")}</h1>}
            {lang.includes("_pt") && <h1>{t("joinus.available-jobs")}</h1>}
          </div>

          {lang.includes("_si") && (
            <div className="container content">
              <JobsList />
            </div>
          )}
          {lang.includes("_pt") && (
            <div className="container content">
              <JobsList />
            </div>
          )}

          {!lang.includes("_si") && !lang.includes("_pt") && (
            <div className="container content">
              <div className="text">
                <h4>{t("joinus.interested")}</h4>
                <h4>
                  {t("joinus.sendusemail")}{" "}
                  <span className="enaslov">{t("joinus.email")}</span>{" "}
                  {t("joinus.sendusmail2")}
                </h4>
              </div>
            </div>
          )}          
        </div>
      </div>
    </div>    
  );
};

export default JoinUs;
