import React, { useEffect } from "react";
import "./ElSupply.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Cardtso from "./Elements/Cardtso";
import Cardcompanies from "./Elements/Cardcompanies";
import Cardstar from "./Elements/Cardstar";
import VideoLower from "./Elements/VideoLower";
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
const ElSupply = () => {
    const { t } = useTranslation();
    var lang = i18n.language;

    document.title = t("page-title.elsupply");

    return (
        <div className="UIElsupply">
            <div className="container-fluid background">
                <div className="bg-image">
                    <VideoLower />
                </div>
                <div className="container">
                    <div className="heading">
                        <h2>{t("tso.ngenponudba")}</h2>
                        <h1>{t("pages.elsupply")}</h1>
                    </div>
                </div>
            </div>
            <div className="container hero-image">
                <img
                    src={`${process.env.PUBLIC_URL}/images/products/elsupply.png`}
                    alt=""
                    className="starter-whatwedo"
                />
            </div>
            <div className="overlay">
                <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tr.svg`} alt="" />
            </div>

            <div className="container hero-cards-holder">
                <div className="hero-cards">
                    <div className="hero-card mb-4">
                        <h2 className="title">{t("elsupply.card1_title")}</h2>
                        <p className="text">{t("elsupply.card1_text")}</p>
                        <div class="readmore"><HashLink to={t('url.elsupply') + '#functions'} exact>{t("products.readmore")}</HashLink></div>
                    </div>
                    <div className="hero-card mb-4">
                        <h2 className="title">{t("elsupply.card2_title")}</h2>
                        <a href="https://apps.apple.com/si/app/ngen-energy/id6448929330" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/appstore.png`} alt="" /></a>
                        <a href="https://play.google.com/store/apps/details?id=ngen.mobile.app" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/googleplay.png`} alt="" /></a>
                    </div>
                </div>
                <div className="hero-cards">
                    <div className="hero-card mb-4">
                        <h2 className="title">{t("elsupply.card3_title")}</h2>
                        <p className="text">{t("elsupply.card3_text")}</p>
                        <div class="readmore"><HashLink to={t('url.elsupply') + '#functions'} exact>{t("products.readmore")}</HashLink></div>
                    </div>
                    <div className="hero-card mb-4">
                        <h2 className="title">{t("elsupply.card4_title")}</h2>
                        <p className="text">{t("elsupply.card4_text")}</p>
                        <div class="readmore"><HashLink to={t('url.elsupply') + '#functions'} exact>{t("products.readmore")}</HashLink></div>
                    </div>
                    <div className="hero-card mb-4">
                        <h2 className="title">{t("elsupply.card5_title")}</h2>
                        <p className="text">{t("elsupply.card5_text")}</p>
                        <div class="readmore"><HashLink to={t('url.elsupply') + '#functions'} exact>{t("products.readmore")}</HashLink></div>
                    </div>
                    <div className="hero-card mb-4">
                        <h2 className="title">{t("elsupply.card6_title")}</h2>
                        <p className="text">{t("elsupply.card6_text")}</p>
                        <div class="readmore"><HashLink to={t('url.elsupply') + '#virtual'} exact>{t("products.readmore")}</HashLink></div>
                    </div>
                </div>
            </div>

            <div className="container content">
                <div className="text">
                    <p dangerouslySetInnerHTML={{ __html: t("elsupply.text_1") }}></p>
                    <p dangerouslySetInnerHTML={{ __html: t("elsupply.text_2") }}></p>
                </div>
            </div>

            <div className="container content">
                <div className="heading">
                    <h4>{t("elsupply.small_title_1")}</h4>
                    <h3>{t("elsupply.komu_omogocamo")}</h3>
                </div>
            </div>
            <div className="container medium_cards">
                <div className="medium_card">
                    <NavLink to={t('url.supplyHouseholds')} exact>
                        <img src={`${process.env.PUBLIC_URL}/images/cards/card-households-els.png`} alt="" />
                        <h2 className="title">{t("elsupply.households_title")}</h2>
                        <div class="readmore"><NavLink to={t('url.supplyHouseholds')} exact>{t('products.readmore')}</NavLink></div>
                    </NavLink>
                </div>
                <div className="medium_card">
                    <NavLink to={t('url.supplyCompanies')} exact>
                        <img src={`${process.env.PUBLIC_URL}/images/cards/card-companies-els.png`} alt="" />
                        <h2 className="title">{t("elsupply.companies_title")}</h2>
                        <div class="readmore"><NavLink to={t('url.supplyCompanies')} exact>{t('products.readmore')}</NavLink></div>
                    </NavLink>
                </div>
            </div>

            <div className="container content" id="functions">
                <div className="heading">
                    <h4>{t("elsupply.small_title_1")}</h4>
                    <h3>{t("elsupply.predstavitev")}</h3>
                </div>
            </div>
            <div className="container phone_cards">
                <div className="phone_card">
                    <img src={`${process.env.PUBLIC_URL}/images/phone_1.png`} alt="" />
                    <div className="heading">
                        <h4>{t("elsupply.predst_card_1_title")}</h4>
                        <p>{t("elsupply.predst_card_1_text")}</p>
                    </div>
                </div>
                <div className="phone_card">
                    <img src={`${process.env.PUBLIC_URL}/images/phone_2.png`} alt="" />
                    <div className="heading">
                        <h4>{t("elsupply.predst_card_2_title")}</h4>
                        <p>{t("elsupply.predst_card_2_text")}</p>
                    </div>
                </div>
                <div className="phone_card">
                    <img src={`${process.env.PUBLIC_URL}/images/phone_3.png`} alt="" />
                    <div className="heading">
                        <h4>{t("elsupply.predst_card_3_title")}</h4>
                        <p>{t("elsupply.predst_card_3_text")}</p>
                    </div>
                </div>
                <div className="phone_card">
                    <img src={`${process.env.PUBLIC_URL}/images/phone_4.png`} alt="" />
                    <div className="heading">
                        <h4>{t("elsupply.predst_card_4_title")}</h4>
                        <p>{t("elsupply.predst_card_4_text")}</p>
                    </div>
                </div>
            </div>

            <div className="container content">
                <div className="heading">
                    <h3>{t("elsupply.functions_title")}</h3>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="functions">
                            <div className="function">
                                <div className="function_number"><span>1</span></div>
                                <div className="function_title heading">
                                    <h4>{t("elsupply.households.function_title_1")}</h4>
                                    {t("elsupply.households.function_text_1")}</div>
                            </div>
                            <div className="function">
                                <div className="function_number"><span>2</span></div>
                                <div className="function_title heading">
                                    <h4>{t("elsupply.households.function_title_2")}</h4>
                                    {t("elsupply.households.function_text_2")}</div>
                            </div>
                            <div className="function">
                                <div className="function_number"><span>3</span></div>
                                <div className="function_title heading">
                                    <h4>{t("elsupply.households.function_title_3")}</h4>
                                    {t("elsupply.households.function_text_3")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img
                            src={`${process.env.PUBLIC_URL}/images/phones.png`}
                            alt=""
                        />
                    </div>
                </div>

            </div>
            {lang.includes("_si") && (
                <div className="container-fluid darkgrey samooskrba my-5 py-5">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-lg-5 d-flex flex-column justify-content-center align-items-center">
                                    <h2>{t("elsupply.nds-title")}</h2>
                                    <h3 dangerouslySetInnerHTML={{ __html: t("elsupply.nds-smalltitle") }} class="mb-5"></h3>
                                <NavLink to={t('url.novadobasamooskrbe')} className="btn btn-secondary" exact>{t('elsupply.wannaknowmore')}</NavLink>
                            </div>
                            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-center">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/nds-intro.png`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* virtualne skupine */}
            <div className="container graybox" id="virtual">
                <div className="content">
                    <div className="row">
                        <div className="col-md-9 col-lg-7">
                            <h2>{t("elsupply.virtual_groups_title")}</h2>
                            <p>{t("elsupply.virtual_groups_text")}</p>
                        </div>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/solarimg.png`}
                            alt="" class="solarimage"
                        />
                    </div>
                </div>
            </div>

            <div className="container mb-5">
                <div className="heading mb-3 text-start">
                    <h3>{t("elsupply.households.sm_title")}</h3>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-7 col-lg-9">
                        <div className="text">
                            {t("elsupply.households.sm_text_1")}
                            <div className="meter_box greybg">
                                <div className="box_content">
                                    {t("elsupply.households.sm_text_2")}
                                    <div className="box_row">
                                        <a href="https://apps.apple.com/si/app/ngen-energy/id6448929330" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/appstore.png`} alt="" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=ngen.mobile.app" target="_blank" rel="noopener noreferrer"><img src={`${process.env.PUBLIC_URL}/images/googleplay.png`} alt="" /></a>
                                    </div>
                                </div>
                                <img src={`${process.env.PUBLIC_URL}/images/products/sg_logo.png`} alt="" className="sglogo" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-3">
                        <img src={`${process.env.PUBLIC_URL}/images/products/smart_meter.png`} alt="" className="smart_meter" />
                    </div>
                </div>
            </div>

        </div >
    );
};

export default ElSupply;
