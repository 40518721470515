import React from 'react';
import './ComSupply.css';
import { useTranslation } from 'react-i18next';
import Cardstorage from './Elements/Cardstorage';
import Cardsolar from './Elements/Cardsolar';
import Cardflex from './Elements/Cardflex';
import VideoLower from './Elements/VideoLower';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';

const ComSupply = () => {
  const { t } = useTranslation();

let className = '';
if (i18n.language == 'en_si' || i18n.language == 'en_at' || i18n.language == 'de_at') {
  className += ' hidden';
}
var lang = i18n.language;

  document.title = t("page-title.comsupply");

  return (

    <div className="UIComSupply">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t('tso.ngenponudba')}</h2>
            <h1>{t('pages.comsupply')}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img src={`${process.env.PUBLIC_URL}/images/supply-products-header-image.png`} alt={t('pages.com-flex')} className='starter-whatwedo' />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tl.svg`} alt="" />
      </div>
      <div className="container content">
        <div className="text">
          <h4>{t('companies.heading4')}</h4>
          <p>{t('companies.text-5')}</p>
          </div>
          {lang.includes('_si') && (
          <div className="text">
            <span id="pricesXLS" className={className}>
            <h4>{t('companies.heading5')}</h4>
            <br />
            <a href="https://www.bsp-southpool.com/trgovalni-podatki.html?file=files/documents/trading/MarketResultsAuction.xlsx&cid=1239&ts=1668772656" target="_blank" className='btn-primary'>{t('companies.button-text-1')}</a>
            <p></p></span>
          <p>{t('companies.text-7')} elektrika@ngentrading.si</p>
        </div>
          )}
      </div>
      <div className="container-fluid main">
        <div className="container cards">
          <div className="heading">
            <h4>{t('tso.ngenponudba')}</h4>
            <h3>{t('tso.lookat')}</h3>
          </div>
          <div className="row cards">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardstorage />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardsolar />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardflex />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ComSupply