import React from 'react'
import './Home.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cardhouseholds from './Elements/Cardhouseholds'
import Cardcompanies from './Elements/Cardcompanies'
import Cardtso from './Elements/Cardtso'
import { HashLink } from 'react-router-hash-link';


const Home = () => {
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  const { t, i18n } = useTranslation();

  document.title = t("page-title.home");

  return (
    <div className="UIHome">
      <div className="container-fluid header">
        <video loop autoPlay="true" muted playsInline poster={`${process.env.PUBLIC_URL}/images/homevideo-poster.jpg`} className='header-video' onLoadedData={onLoadedData} style={{ opacity: isVideoLoaded ? 1 : 0 }}>
          <source src={`${process.env.PUBLIC_URL}/videos/ngen_new_video.mp4`} type="video/mp4" />
        </video>
        {/* <div className="container title">
          <div className="heading">
            <h2>{t('home.welcome')}</h2>
            <h1>{t('home.heading')}</h1>
          </div>
        </div> */}
      </div>

      <div className="container-fluid d-flex d-xl-none flex-column align-items-center additional-links">

        {/* <HashLink to={t('url.gospodinjstva') + '#doee'} className={`link additional ongreen`}>{t('pages.dobava_elektrike')}</HashLink> */}
        {/* <HashLink to={t('url.gospodinjstva') + '#bess'} className={`link additional ongreen`}>{t('pages.hranilniki')}</HashLink> */}
        <a href={t('url.sgconnect')} className={`link additional ongreen`}>{t('pages.sgconnect')}</a>
        <a href="https://justcharge.io" target='_blank' className={`link additional ongreen`}>JustCharge</a>
        {i18n.language === 'sl_si' && (
          <a href={t('url.elsupply')} className={`link additional ongreen`}>{t('pages.elsupply')}</a>
        )}
      </div>
      <div className="container cards">
        <div className="heading">
          {/* <h4>{t('home.discover')}</h4> */}
          <h3>{t('home.our-offer')}</h3>
        </div>
        <div className="row cards">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Cardhouseholds />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Cardcompanies />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <Cardtso />
          </div>

        </div>
      </div>
      <div className="container-fluid bigwhoweare">
        <img className="bg-image" src={`${process.env.PUBLIC_URL}/images/home-background.svg`} alt="" />
        <div className="container whoweare">
          <div className="row">
            <div className="col-md-8 col-lg-6">
              <div className="container heading">
                {/* <h4>{t('home.aboutus')}</h4> */}
                <h3>{t('home.whoweare')}</h3>
                <p>{t('home.text-whoweare')}</p>
                <br />
                <NavLink to={t('url.whoweare')} className="btn-primary">{t('products.readmore')}</NavLink>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={`${process.env.PUBLIC_URL}/images/home-whoweare-header-image.png`} alt="" className='whoweare' />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bigwhatwedo">
        <div className="container whatwedo">
          <div className="row">
            <div className="col-md-8 col-lg-6">
              <div className="container heading">
                {/* <h4>{t('home.ngenoffer')}</h4> */}
                <h3>{t('home.whatwedo')}</h3>
                <p>{t('home.text-whatwedo')}</p>
                <br />
                <NavLink to={t('url.whatwedo')} className="btn-primary">{t('products.readmore')}</NavLink>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img src={`${process.env.PUBLIC_URL}/images/home-whatwedo-header-image.png`} alt="" className='whatwedo' />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Home