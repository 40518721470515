import React from 'react'
import './WhatWeDo.css';
import { useTranslation } from 'react-i18next';
import VideoLower from './Elements/VideoLower';
import Cardhouseholds from './Elements/Cardhouseholds';
import Cardcompanies from './Elements/Cardcompanies';
import Cardtso from './Elements/Cardtso';
import i18n from 'i18next';
const WhatWeDo = () => {

  var lang = i18n.language;
  const { t } = useTranslation();

  document.title = t("page-title.whatwedo");

  const textCenter = {
    textAlign: "center"
  }
  return (
    <div className="UIWhatWeDo">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t('storage.ngen-products')}</h2>
            <h1>{t('products.whatwedo')}?</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img src={`${process.env.PUBLIC_URL + '/images/' + lang + "/header-wwd.png"}`} alt="" className='starter-whatwedo' />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tl.svg`} alt="" />
      </div>

      {lang.includes('sl_') && (
        <div className="container content">
        <div className="text">
          <h4>{t('titles.whatwedoimg')}</h4>
          <br />
          <p>{t('storage.product-text-7')}</p>
          <p>{t('storage.product-text-8')}</p>
          <p>{t('storage.product-text-9')}</p>
        </div>
      </div>
      )}

      {!lang.includes('sl_') && (
      <div className="container content">
        <div className="text">          
          <p>{t('storage.product-text-7')}</p>
          <p>{t('storage.product-text-8')}</p>
          <h4>{t('titles.whatwedoimg')}</h4>                   
          <p>{t('storage.product-text-9')}<br/>{t('storage.product-text-10')}</p>
          <h4>{t('titles.whatwedo-storage')}</h4>                   
          <p>{t('storage.product-text-11')}<br/>{t('storage.product-text-12')}</p>
          <h4>{t('titles.whatwedo-where')}</h4>                  
          <p>{t('storage.product-text-13')}<br/>{t('storage.product-text-14')}</p>
        </div>
      </div>
      )}
      
      <div className="container-fluid main">
        <div className="container cards">
          <div className="heading">
            <h4>{t('titles.whatwedo1')}</h4>
            <h3>{t('titles.whatwedo2')}</h3>
          </div>
          <div className="row cards">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Cardhouseholds />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Cardcompanies />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <Cardtso />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default WhatWeDo