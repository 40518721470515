import React from "react";
import './Contact.css'
import { useTranslation } from 'react-i18next';
import Form from '../Form.js';
import i18n from 'i18next';
import { NavLink } from 'react-router-dom';

const Contact = () => {
  const { t } = useTranslation();
  var lang = i18n.language;

  document.title = t("page-title.contact");

  return (
    <div className="UIContact">

      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="heading">
                <h2>{t('contact.getintouch')}</h2>
                <h1>{t('contact.contactus')}</h1>
              </div>
              {lang.includes('_si') && (
                <div className="text">
                  <p><strong>{t('contact.address')}:</strong> Moste 101, 4274 Žirovnica, Slovenija<br />
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+38645812020">+386 (0)4 581 20 20</a><br />
                    <strong>{t('contact.email')}:</strong> info@ngen.si
                    <br /><br />
                    <strong>{t('contact.hranilniki')}:</strong> powerwall@ngen.si<br />
                    <strong>{t('contact.dobinodkup')}:</strong> elektrika@ngentrading.si</p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>
                </div>
              )}
              {lang.includes('_pl') && (
                <div className="text">
                  <p><strong>{t('contact.address')}:</strong> ul. Grójecka 34 lok. 8, 02-308 Warszawa<br />
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+38645812020">+386 (0)4 581 2020</a><br />
                    <strong>{t('contact.email')}:</strong> info@ngen-energy.pl
                    <br /><br />
                    <strong>{t('contact.hranilniki')}:</strong> powerwall@ngen.si<br />
                    <strong>{t('contact.dobinodkup')}:</strong> elektrika@ngentrading.si</p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>
                </div>
              )}
              {lang.includes('_pt') && (
                <div className="text">
                  <p><strong>{t('contact.address')}:<br /></strong>Praça Nuno Rodrigues dos Santos 7, 1600-171 Lisboa, Portugal<br />
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+351215864038">+351 215 864 038</a><br />
                    <strong>{t('contact.email')}:</strong> info@ngen.pt
                    <br /><br />
                    <strong>{t('contact.hranilniki')}:</strong> sales@ngen.pt<br />
                    <strong>{t('contact.dobinodkup')}:</strong> info@ngen.pt</p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>
                </div>
              )}
              {lang.includes('_hr') && (
                <div className="text">
                  <p>                    
                    <strong>{t('contact.address')}:<br /></strong>
                    <strong>NGEN Smart Energy Systems d.o.o.</strong><br />
                    Avenija Većeslava Holjevca 40, Zagreb 10000, Hrvatska<br />
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+38512111889">+385 1 2111 889</a><br />
                    <strong>{t('contact.email')}:</strong> info@ngen.hr
                    <br /><br />
                    <strong>{t('contact.hranilniki')}:</strong> powerwall@ngen.si<br />
                    <strong>{t('contact.dobinodkup')}:</strong> elektrika@ngentrading.si</p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>
                </div>
              )}
              {lang.includes('_at') && (
                <div className="text">
                  <p>
                    <strong>{t('contact.address')}:<br /></strong>Villacher Ring 59, 9020 Klagenfurt am Wörthersee, Österreich<br />                    
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+43463207040">+43 (0) 463 20 70 40</a><br />
                    <strong>{t('contact.email')}:</strong> office@ngen.at
                  </p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>&nbsp;&nbsp;
                  <a href="https://instagram.com/ngen_austria?igshid=YmMyMTA2M2Y=" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-instagram"></i></a>
                </div>
              )}
              {lang.includes('_de') && (
                <div className="text">
                  <p>
                    <strong>NGEN Germany GmbH </strong>
                    <strong>{t('contact.address')}:<br /></strong>Stehnweg 2, 63500 Seligenstadt, Deutschland<br />                    
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+43463207040">+43 (0) 463 20 70 40</a><br />
                    <strong>{t('contact.email')}:</strong> info@ngen-group.eu
                  </p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>                  
                </div>
              )}
              {lang.includes('_es') && (
                <div className="text">
                  <p>
                    <strong>{t('contact.address')}:</strong> Moste 101, 4274 Žirovnica, Slovenija<br />                    
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+38645812020">+386 (0)4 581 20 20</a><br />
                    <strong>{t('contact.email')}:</strong> info@ngen-group.eu
                  </p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>&nbsp;&nbsp;                  
                </div>
              )}
              {lang.includes('_gb') && (
                <div className="text">
                  <p>
                    <strong>NGEN LIMITED</strong><br />
                    <strong>{t('contact.address')}:</strong> 167-169 Great Portland Street, London, England, W1W 5PF<br />                    
                    <strong>{t('contact.phone')}:</strong> <a href="tel:+443300243738">+44 330 024 37 38</a><br />
                    <strong>{t('contact.email')}:</strong> info@ngen.co.uk
                  </p>

                  <p className="followus"><strong>{t('contact.followus')}</strong></p>
                  <a href="https://www.linkedin.com/company/ngen-d-o-o/" target='_blank' rel="noopener noreferrer" className='btn-primary linkedin'><i className="fa-brands fa-linkedin-in"></i></a>&nbsp;&nbsp;                  
                </div>
              )}

            </div>
            <div className="col-sm-12 col-md-12 col-lg-5">
              <Form />
            </div>
          </div>
        </div>
      </div>
      <div className="container image">
        <img src={`${process.env.PUBLIC_URL}/images/contact-header-image.png`} alt="Contact ngen" />
      </div>
      <div className="container video">
        <video id="video" controls poster={`${process.env.PUBLIC_URL}/images/poster.jpg`} controlsList="nodownload">
          <source src={`${process.env.PUBLIC_URL}/videos/presentation.mp4`} type="video/mp4" />
        </video>
      </div>
    </div>

  )
}

export default Contact