import React from "react";
import "./LegalNotice.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Documents = () => {
  const { t } = useTranslation();

  document.title = t("page-title.documents");

  return (
    <div className="UILegalNotice">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="heading">
                <h1>{t("pages.documents")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container content">
        <div className="text documents">
        <p>{t("documents.docs2")}</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/obr-preklic-oop"
              >
                Obrazec za preklic privolitve za obdelavo osebnih podatkov
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/obr-uveljavljanje-ppop"
              >
                Obrazec za uveljavljanje pravice potrošnika do odstopa od
                pogodbe
              </a>
            </li>
          </ul>
          
          <p>{t("documents.docs4")}</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/proizvodni-viri-pe-2019"
              >
                Proizvodni viri za proizvodnjo elektrike za leto 2019
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/proizvodni-viri-pe-2020"
              >
                Proizvodni viri za proizvodnjo elektrike za leto 2020
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/proizvodni-viri-pe-2021"
              >
                Proizvodni viri za proizvodnjo elektrike za leto 2021
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/reklamacije-pritozbe"
              >
                Reklamacije in pritožbe
              </a>
            </li>
          </ul>
          <p>{t("documents.docs5")}</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/ugodnosti-za-odjemalce"
              >
                Ugodnosti za odjemalce za leto 2024 - samooskrba letni obračun
              </a>
            </li>
          </ul>
          <br />
          <h2>Ngen</h2>
          <p>{t("documents.docs1")}</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-nsit-sp001"
              >
                Splošni pogoji podjetja NGEN d.o.o. za nudenje storitev na
                izravnalnem trgu (SP-001) -{" "}
                <span>veljajo od vključno 1.11.2019 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-deepo"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne
                energije poslovnim odjemalcem -{" "}
                <span>veljajo od vključno 24.6.2022 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-dee-sgo"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne
                energije in samooskrbo gospodinjskih odjemalcev z električno
                energijo - <span>veljajo od vključno 1.1.2023 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-dee-sgo-mo"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne
                energije in samooskrbo gospodinjskih odjemalcev z električno
                energijo - mesečni obračun - <span>veljajo od vključno 1.10.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-dee-sgo-nds"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne energije 
                in samooskrbo gospodinjskih odjemalcev z električno energijo - 
                Nova doba samooskrbe - <span>veljajo od vključno 1.10.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-dee-smpo"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne
                energije in samooskrbo malih poslovnih odjemalcev z električno
                energijo - <span>veljajo od vključno 1.1.2023 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-deego"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne
                energije gospodinjskim odjemalcem - paket NGEN -{" "}
                <span>veljajo od vključno 24.6.2022 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/sp-deempo"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne
                energije malim poslovnim odjemalcem - paket NGEN -{" "}
                <span>veljajo od vključno 19.7.2022 dalje.</span>
              </a>
            </li>
          </ul>
          <p>{t("documents.docs3")}</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-ee-sgo"
              >
                Cenik električne energije za samooskrbo gospodinjskih odjemalcev
                - <span>velja od vključno 1.1.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-ee-sgo-mo"
              >
                Cenik električne energije za samooskrbo gospodinjskih odjemalcev mesečni obračun
                - <span>velja od vključno 1.1.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-ee-smpo"
              >
                Cenik električne energije za samooskrbo malih poslovnih
                odjemalcev - <span>velja od vključno 1.1.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-ee-go"
              >
                Cenik električne energije za gospodinjske odjemalce - paket NGEN
                - <span>velja od vključno 1.1.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-ee-mpo"
              >
                Cenik električne energije za male poslovne odjemalce - paket
                NGEN - <span>velja od vključno 1.1.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-nova-doba-samooskrbe"
              >
                Cenik Nova doba samooskrbe - <span>velja od vključno 1.1.2024 dalje.</span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-ngen-ps"
              >
                Cenik za NGEN pametni števec
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-samofakturiranje"
              >
                Cenik za samofakturiranje
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/cenik-dodatne-storitve"
              >
                Cenik za dodatne storitve
              </a>
            </li>
          </ul>
          <br />
          <h2>MOONelektrika</h2>
          
          <p>{t("documents.docs1")}</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-pogoji-eesgo"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne energije in samooskrbo gospodinjskih odjemalcev z električno energijo paketa MOONelektrika <span></span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-pogoji-deesmpog"
              >
                Splošni pogoji podjetja NGEN d.o.o. za dobavo električne energije in samooskrbo malih poslovnih odjemalcev z električno energijo paketa MOONelektrika <span></span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-pogoji-eego"
              >
                Splošni pogoji podjetja NGEN za dobavo električne energije gospodinjskim odjemalcem - paket MOONelektrika <span></span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-pogoji-deempo"
              >
                Splošni pogoji podjetja NGEN za dobavo električne energije malim poslovnim odjemalcem - Paket MOONelektrika <span></span>
              </a>
            </li>
          </ul>
          <p>{t("documents.docs3")}</p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-cenik-eego"
              >
                Cenik električne energije za gospodinjske odjemalce MOONelektrika <span></span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-cenik-eempo"
              >
                Cenik električne energije za mali poslovni odjem MOONelektrika <span></span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-cenik-eesmpo"
              >
                Cenik električne energije za samooskrbo gospodinjskih odjemalcev MOONelektrika <span></span>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://qr.ngen.si/moon-cenik-smpo"
              >
                Cenik električne energije za samooskrbo malih poslovnih odjemalcev MOONelektrika <span></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Documents;
