import React from "react";
import "./Business.css";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Cardtso from "./Elements/Cardtso";
import Cardhouseholds from "./Elements/Cardhouseholds";
import Cardpartners from "./Elements/Cardpartners";
import VideoLower from "./Elements/VideoLower";
import { NavLink } from "react-router-dom";

const Business = () => {
  const { t } = useTranslation();
  var lang = i18n.language;

  document.title = t('page-title.business')

  return (    
    <div className="UIBusiness">
      <div className="container-fluid background">
        <div className="bg-image">
          <VideoLower />
        </div>
        <div className="container">
          <div className="heading">
            <h2>{t("tso.ngenponudba")}</h2>
            <h1>{t("pages.podjetja")}</h1>
          </div>
        </div>
      </div>
      <div className="container hero-image">
        <img
          src={`${process.env.PUBLIC_URL}/images/products/business.png`}
          alt="Business image"
          className="starter-whatwedo"
        />
      </div>
      <div className="overlay">
        <img src={`${process.env.PUBLIC_URL}/images/w-overlay-tr.svg`} alt="" />
      </div>
      <div className="container content">
        <div className="text">
          <p>{t("business.text-1")}</p>
          <h4>{t("business.heading1")}</h4>
          <p>
            {t("business.text-2")}
            <br />
            {t("business.text-3")}
          </p>
          {lang.includes("sl_") && (
            <div className="text">
              <img
                src={`${
                  process.env.PUBLIC_URL + "/images/" + lang + "/samooskrba.png"
                }`}
                alt="Ngen star shema"
                width="100%"
              />
            </div>
          )}
          {lang.includes("en_") && (
            <div className="text">
              <img
                src={`${
                  process.env.PUBLIC_URL + "/images/" + lang + "/samooskrba.png"
                }`}
                alt="Ngen star shema"
                width="100%"
              />
            </div>
          )}
          {lang.includes("de_") && (
            <div className="text">
              <img
                src={`${
                  process.env.PUBLIC_URL + "/images/" + lang + "/samooskrba.png"
                }`}
                alt="Ngen star shema"
                width="100%"
              />
            </div>
          )}

          <h4>{t("business.heading2")}</h4>
          <p>{t("business.text-4")}</p>
          {lang.includes("sl_si") && (
            <div className="text">
              <p>{t("business.investment")}</p>
              <a
                href="https://star.ngen.si/sl"
                className="btn-primary"
                target="_blank"
              >
                {t("business.button-sun")}
              </a>
              <br />
              <br />
            </div>
          )}

          <h4>{t("business.heading4")}</h4>
          <p>
            {t("business.text-5")}
            <br />
            {t("business.text-6")}
            <br />
            {t("business.text-7")}
            <br />
            {t("business.text-8")}
          </p>

          {lang.includes("sl_si") && (
            <span id="pricesXLS">
              <h4>{t("companies.heading5")}</h4>
              <br />
              <a
                href="https://www.bsp-southpool.com/trgovalni-podatki.html?file=files/documents/trading/MarketResultsAuction.xlsx&cid=1239&ts=1668772656"
                target="_blank"
                className="btn-primary"
              >
                {t("companies.button-text-1")}
              </a>
              <p></p>
            </span>
          )}
          <p>{t("business.contact")}</p>
        </div>
      </div>
      <div className="container-fluid main">
        <div className="container cards">
          <div className="heading">
            <h4>{t("tso.lookat")}</h4>
            <h3>{t("tso.other-products")}</h3>
          </div>
          <div className="row cards">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardtso />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardhouseholds />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <Cardpartners />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
