import { useTranslation } from 'react-i18next';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import './Preloader.css'
import { useCookies } from "react-cookie";
import i18n from 'i18next';

function Preloader(res) {
    const [cookies, setCookie] = useCookies(["introvideo", "langMenu"]);

        setCookie("videointro", "1", {
          path: "/"
        });
        setCookie("langMenu", "1", {
          path: "/"
        });
      

    const navigate = useNavigate()
    const handleVideoEnded = () => {
        navigate('/'+document.documentElement.getAttribute('lang'));
    };

    const { t } = useTranslation();

    const videoRef = useRef(null);
    const videoHandler = (control) => {
        if (control === "unmute") {
            videoRef.current.muted = "";
            videoRef.current.play();
            var icon = "mute";
        } else if(control === "unmute") {
            videoRef.current.muted = "muted";
            var icon = "unmute";
        }
    };
    return (
        <div id="preloader">
            <button class="unmute-icon" onClick={() => videoHandler("unmute")} ><img src={`${process.env.PUBLIC_URL}/images/sound-full-icon.png`} alt="unmute icon" /></button>
            <video src={`${process.env.PUBLIC_URL}/videos/ngen_intro_audio.mp4`} muted ref={videoRef} onEnded={handleVideoEnded} id="preloader-video" autoPlay playsInline poster={`${process.env.PUBLIC_URL}/images/menu-poster.jpg`} >
            </video>

            <NavLink to={t('url.home')} className="intro-skip">{t('home.skip')}</NavLink>
        </div>
        
    )
}

export default Preloader