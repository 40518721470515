import React from "react";
import "./Terms.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Terms = () => {
  const { t } = useTranslation();

  document.title = t("page-title.terms");

  var lang = i18n.language;

  return (
    <div className="UITerms">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="heading">
                <h1>{t("pages.terms")}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container content">        
          <div className="text m-2">
            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_1") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_1_1") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_1_2") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_1_3") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_1_4") }}
              />
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_2") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_2_1") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_2_2") }}
              />              
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_3") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_3_1") }}
              />
              <p
                className="mb-2"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_3_2") }}
              />
              <ul>
                <li dangerouslySetInnerHTML={{
                    __html: t("terms_of_use.text_3_li_1"),}}/>
                <li dangerouslySetInnerHTML={{
                    __html: t("terms_of_use.text_3_li_2"),}}/>
              </ul>
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_3_3") }}
              />
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_4") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_4_1") }}
              />             
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_5") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_5_1") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_5_2") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_5_3") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_5_4") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_5_5") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_5_6") }}
              />
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_6") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_6_1") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_6_2") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_6_3") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_6_4") }}
              />
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_7") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_7") }}
              />
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_8") }}
              />
              <p
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.text_8") }}
              />
            </div>

            <div className="text mb-3">
              <h4
                className="text-uppercase"
                dangerouslySetInnerHTML={{ __html: t("terms_of_use.title_9") }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("terms_of_use.text_9_1"),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("terms_of_use.text_9_2"),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("terms_of_use.text_9_3"),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("terms_of_use.text_9_4"),
                }}
              />
            </div>
          </div>        
      </div>
    </div>
  );
};

export default Terms;
