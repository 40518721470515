import React from 'react';
import './App.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useCookies } from "react-cookie";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
//pages
import Preloader from './components/Preloader';
import Home from './components/Pages/Home';
import WhoWeAre from './components/Pages/WhoWeAre';
import WhatWeDo from './components/Pages/WhatWeDo';
import Products from './components/Pages/Products';
import Contact from './components/Pages/Contact';
import TSO from './components/Pages/TSO';
import Companies from './components/Pages/Business';
import Households from './components/Pages/Households';
import Partners from './components/Pages/Partners';
import RCI from './components/Pages/RCI';
import EEC from './components/Pages/EEC';
import BBSS from './components/Pages/BBSS';
import LegalNotice from './components/Pages/LegalNotice';
import Impressum from './components/Pages/Impressum';
import Terms from './components/Pages/Terms';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import ComBatteries from './components/Pages/ComBatteries';
import ComFlex from './components/Pages/ComFlex';
import ComSolar from './components/Pages/ComSolar';
import ComSupply from './components/Pages/ComSupply';
import JoinUs from './components/Pages/JoinUs';
import ElSupply from './components/Pages/ElSupply';
import Connection from './components/Pages/Connection';
import ScrollToTop from "./ScrollToTop";
import Documents from './components/Pages/Documents';
import CookieConsentComponent from './components/CookieConsentComponent';
import { Helmet } from 'react-helmet';
import JobDescription from './components/Pages/JobDescription';
import SupplyHouseholds from './components/Pages/SupplyHouseholds';
import SupplyCompanies from './components/Pages/SupplyCompanies';
import SupportPortugal from './components/Pages/SupportPortugal';
import ElSelfsuf from './components/Pages/ElSelfsuf';

const App = () => {
  const { t } = useTranslation();
  /*const [cookies, setCookie] = useCookies(["introvideo"]);

  if(!cookies.videointro) {
    if (isMobile) {
      var HomeRoute = <Home />;
    } else {
      var HomeRoute = <Preloader />;
    }
  } else {
    var HomeRoute = <Home />;
  }*/
  var HomeRoute = <Home />;
  var lang = i18n.language;

  return (
    <>
      {lang.includes('_at') && (
        <div>
          <Helmet>
            <title>Energiesystemlösungen der neuen Generation - NGEN</title>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.9/dist/cookieconsent.css" media="print" onload="this.media='all'" />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-K6EC0TH1VB" type="text/plain" data-cookiecategory="analytics"></script>

          </Helmet>
        </div>
      )}
      {lang.includes('de_') && (
        <div>
          <Helmet>
            <title>Energiesystemlösungen der neuen Generation - NGEN</title>
          </Helmet>
        </div>
      )}
      {lang.includes('en_') && (
        <div>
          <Helmet>
            <title>Next generation energy solutions - NGEN</title>
          </Helmet>
        </div>
      )}
      {lang.includes('sl_') && (
        <div>
          <Helmet>
            <title>Energetske sistemske rešitve nove generacije - NGEN</title>
          </Helmet>
        </div>
      )}
      {lang.includes('hr_') && (
        <div>
          <Helmet>
            <title>Rješenja energetskih sustava nove generacije - NGEN</title>
          </Helmet>
        </div>
      )}
      {lang.includes('pl_') && (
        <div>
          <Helmet>
            <title>Rozwiązania systemów energetycznych nowej generacji - NGEN</title>
          </Helmet>
        </div>
      )}
      {lang.includes('pt_') && (
        <div>
          <Helmet>
            <title>Soluções de sistemas de energia da próxima geração - NGEN</title>
          </Helmet>
        </div>
      )}
      {lang.includes('es_') && (
        <div>
          <Helmet>
            <title>Soluciones energéticas de próxima generación - NGEN</title> 
          </Helmet>
        </div>
      )}

      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          {/* <Route index element={HomeRoute} /> */}
          <Route index element={<Home />} />
          <Route exact path={t('url.home')} element={<Home />} />
          <Route exact path={t('url.whoweare')} element={<WhoWeAre />} />
          <Route exact path={t('url.whatwedo')} element={<WhatWeDo />} />
          <Route exact path={t('url.products')} element={<Products />} />
          <Route exact path={t('url.contact')} element={<Contact />} />
          <Route exact path={t('url.tso')} element={<TSO />} />
          <Route exact path={t('url.podjetja')} element={<Companies />} />
          <Route exact path={t('url.gospodinjstva')} element={<Households />} />
          <Route exact path={t('url.elsupply')} element={<ElSupply />} />
          <Route exact path={t('url.supplyHouseholds')} element={<SupplyHouseholds />} />
          <Route exact path={t('url.supplyCompanies')} element={<SupplyCompanies />} />
          <Route exact path={t('url.novadobasamooskrbe')} element={<ElSelfsuf />} />
          <Route exact path={t('url.novadobasamooskrbe')+'#soo'} element={<ElSelfsuf />} />
          <Route exact path={t('url.novadobasamooskrbe')+'#hee'} element={<ElSelfsuf />} />
          <Route exact path={t('url.novadobasamooskrbe')+'#nds'} element={<ElSelfsuf />} />

          <Route exact path={t('url.elsupply')+'#functions'} element={<ElSupply />} />
          <Route exact path={t('url.elsupply')+'#virtual'} element={<ElSupply />} />
          <Route exact path={t('url.gospodinjstva')+'#doee'} element={<Households />} />
          <Route exact path={t('url.gospodinjstva')+'#bess'} element={<Households />} />
          <Route exact path={t('url.gospodinjstva')+'#sgconnect'} element={<Households />} />

          <Route exact path={t('url.poslovnipartnerji')} element={<Partners />} />

          <Route exact path={t('url.com-batteries')} element={<ComBatteries />} />
          <Route exact path={t('url.com-flex')} element={<ComFlex />} />
          <Route exact path={t('url.com-solar')} element={<ComSolar />} />
          <Route exact path={t('url.com-supply')} element={<ComSupply />} />

          <Route exact path={t('url.eec')} element={<EEC />} />
          <Route exact path={t('url.rci')} element={<RCI />} />
          <Route exact path={t('url.bbss')} element={<BBSS />} />
          <Route exact path={t('url.legalnotice')} element={<LegalNotice />} />
          <Route exact path={t('url.impressum')} element={<Impressum />} />
          <Route exact path={t('url.privacypolicy')} element={<PrivacyPolicy />} />
          <Route exact path={t('url.terms')} element={<Terms />} />
          <Route exact path={t('url.documents')} element={<Documents />} />
          <Route exact path={t('url.joinourteam')} element={<JoinUs />} />
          <Route exact path={t('url.job-description') + '/:index'} element={<JobDescription />} />
          <Route exact path={t('url.ngenconnection')} element={<Connection />} />

          <Route exact path={t('url.SupportPortugal')} element={<SupportPortugal />} />
          

          <Route path="*" element={<Home />} />

        </Routes>
        {lang.includes('_at') && (
        <CookieConsentComponent />
        )}
        <Footer />
      </BrowserRouter>
    </>

  );
}

export default App;
