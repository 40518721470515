import React from "react";
import "./SupportPortugal.css";
import { useTranslation } from "react-i18next";
import Form from "../Form.js";
import i18n from "i18next";
import { NavLink } from "react-router-dom";
import FormSupportPortugal from "../FormSupportPortugal.js";

const SupportPortugal = () => {
  const { t } = useTranslation();
  var lang = i18n.language;

  document.title = t("page-title.contact");

  return (
    <div className="UIContact">
      <div className="container-fluid background">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="heading">
                <h2>{t("contact.support")}</h2>
                <h1>{t("contact.contactus")}</h1>
              </div>

              <div className="text">
                <p>
                  <strong>
                    NGEN Smart Grids LDA & NGEN Smart Trading D.o.o.
                  </strong>
                  <br />
                  <strong>{t("contact.address")}:</strong> Praça Nuno Rodrigues
                  dos Santos 7, 1600-171 Lisboa, Portugal
                  <br />
                  <strong>{t("contact.phone")}:</strong>{" "}
                  <a href="tel:+351215864038">+351 215 864 038</a>
                  <br />
                  <strong>{t("contact.email")}:</strong> support@ngen.pt
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5">
              <FormSupportPortugal />
            </div>
          </div>
        </div>
      </div>
      <div className="container image">
        <img
          src={`${process.env.PUBLIC_URL}/images/contact-header-image.png`}
          alt="Contact ngen"
        />
      </div>
      <div className="container video">
        <video
          id="video"
          controls
          poster={`${process.env.PUBLIC_URL}/images/poster.jpg`}
          controlsList="nodownload"
        >
          <source
            src={`${process.env.PUBLIC_URL}/videos/presentation.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default SupportPortugal;
