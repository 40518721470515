import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import i18n from "i18next";

const Cardstar = () => {
  var lang = i18n.language;
  const { t } = useTranslation();
  return (
    <>
      {lang === "sl_si" && (
        <a
          href="https://star.ngen-group.eu/sl"
          target="_blank"
          className="card-link"
        >
          <div className="card">
            <img
              src={`${process.env.PUBLIC_URL}/images/cards/ngen-star.png`}
              alt="Ngen Star"
            />
            <div className="card-text">
              <h3>Ngen Star</h3>
            </div>
            <div className="readmore">
              <a href="https://star.ngen-group.eu/sl" target="_blank">
                {t("storage.product-text-4")}
              </a>
            </div>
          </div>
        </a>
      )}
      {lang === "de_at" && (
        <a
          href="https://star.ngen-group.eu/at"
          target="_blank"
          className="card-link"
        >
          <div className="card">
            <img
              src={`${process.env.PUBLIC_URL}/images/cards/ngen-star.png`}
              alt="Ngen Star"
            />
            <div className="card-text">
              <h3>Ngen Star</h3>
            </div>
            <div className="readmore">
              <a href="https://star.ngen-group.eu/at" target="_blank">
                {t("storage.product-text-4")}
              </a>
            </div>
          </div>
        </a>
      )}
      {lang !== "sl_si" && lang !== "de_at" && (
        <a
          href="https://star.ngen-group.eu/en"
          target="_blank"
          className="card-link"
        >
          <div className="card">
            <img
              src={`${process.env.PUBLIC_URL}/images/cards/ngen-star.png`}
              alt="Ngen Star"
            />
            <div className="card-text">
              <h3>Ngen Star</h3>
            </div>
            <div className="readmore">
              <a href="https://star.ngen-group.eu/en" target="_blank">
                {t("storage.product-text-4")}
              </a>
            </div>
          </div>
        </a>
      )}

      <div className="card-shadow">
        <img
          src={`${process.env.PUBLIC_URL}/images/cards/shadow.png`}
          alt="shadow card"
        />
      </div>
    </>
  );
};

export default Cardstar;
