import React from 'react'

const VideoLower = () => {
  return (
    <video loop autoPlay="true" muted playsInline poster={`${process.env.PUBLIC_URL}/images/poster-header.png`} className='header-video'>
      <source src={`${process.env.PUBLIC_URL}/videos/background-lower.mp4`} type="video/mp4" />
    </video>
  )
}

export default VideoLower